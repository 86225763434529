import React from 'react';

export default function IntroductionNeeds() {
  return (
    <section id="introduction" className="py-16 px-4 bg-[#faf9f6] md:p-24">
      <div className="max-w-[1024px] m-auto h-full flex justify-center items-center">
        <div className="flex flex-col gap-24 md:gap-36">
          <div className="flex gap-4 items-center" data-aos="zoom-in-up">
            <p className="text-5xl">👩🏻‍💼</p>
            <div className="flex flex-col gap-2" data-aos="">
              <p className="text-md font-bold md:text-2xl">
                &quot;10억 미만의 고객들에게도 50억원 이상의 우량한 부동산에 투자하게 해주고 싶어요.&quot;
              </p>
              <p className="text-md">(강남 부동산 전문 부동산 중개회사)</p>
            </div>
          </div>
          <div className="flex gap-4 items-center justify-end" data-aos="zoom-in-up">
            <div className="flex flex-col gap-2">
              <p className="text-md text-right font-bold md:text-2xl">
                &quot;저는 부동산 사업에만 집중하고 싶어요. 투자자가 많아지면 관리할게 너무 많아져서 어렵거든요.&quot;
              </p>
              <p className="text-md text-right">(디벨로퍼 A)</p>
            </div>
            <p className="text-5xl">🧑🏻‍💼</p>
          </div>
          <div className="flex gap-4 items-center" data-aos="zoom-in-up">
            <p className="text-5xl">🤷🏻‍♂️</p>
            <div className="flex flex-col gap-2">
              <p className="text-md font-bold md:text-2xl">
                &quot;공투 계약서는 어떻게 써야하죠? 법과 세금을 신경써야 하는데 변호사와 회계사가 말이 달라요&quot;
              </p>
              <p className="text-md">(난감한 공동투자 리드)</p>
            </div>
          </div>
          <div className="flex gap-4 items-center justify-end" data-aos="zoom-in-up">
            <div className="flex flex-col gap-2">
              <p className="text-md text-right font-bold md:text-2xl">
                &quot;공투에서 대출을 받을때, 연대보증은 누가 서야하고 공투법인의 주소와 지분율은 어떻게 정하면
                좋을까요?&quot;
              </p>
              <p className="text-md text-right">(걱정하는 공투 리드)</p>
            </div>
            <p className="text-5xl">💁🏻</p>
          </div>
        </div>
      </div>
    </section>
  );
}
