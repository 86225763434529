import React from 'react';

export default function Performance() {
  return (
    <section id="performance" className="flex flex-col gap-16 py-16 px-4 bg-[#faf9f6]">
      <h2 className="text-3xl font-bold text-center">Major Projects</h2>
      <ul className="max-w-5xl mx-auto grid grid-cols-1 md:grid-cols-3 gap-16">
        <li className="flex flex-col gap-4" data-aos="fade-right">
          <h3 className="text-3xl font-bold">96억원</h3>
          <div className="flex flex-col gap-4">
            <img className="rounded-lg h-[250px] object-cover" src="/img/project-1.png" alt="" />
            <p className="text-xl font-bold">글로벌 커피숍 프렌차이즈</p>
            <p className="text-md">경기도에 위치한 부지에 선임차하여 리스크를 최소화한후 부동산을 개발하는 프로젝트</p>
            <p className="text-md">연환산수익률 33% | 투자기간 3년</p>
          </div>
        </li>
        <li className="flex flex-col gap-4" data-aos="fade-right">
          <h3 className="text-3xl font-bold">65억원</h3>
          <div className="flex flex-col gap-4">
            <img className="rounded-lg h-[250px] object-cover" src="/img/project-2.png" alt="" />
            <p className="text-xl font-bold">국내 대형 리테일 마스터리스</p>
            <p className="text-md">국내 1위 패스트 리테일러가 선임차하여 개발하는 프로젝트</p>
            <p className="text-md">연환산 수익률 100% | 투자기간 3년</p>
          </div>
        </li>
        <li className="flex flex-col gap-4" data-aos="fade-right">
          <h3 className="text-3xl font-bold">21억원</h3>
          <div className="flex flex-col gap-4">
            <img className="rounded-lg h-[250px] object-cover" src="/img/project-3.png" alt="" />
            <p className="text-xl font-bold">부산 도시재생 사업</p>
            <p className="text-md">부산의 구도심에 신축 주거 공간을 개발하는 사업</p>
            <p className="text-md">연환산 수익률 15.41% | 투자기간 15개월</p>
          </div>
        </li>
      </ul>
      <p className="text-xs text-gray-400 mx-auto">
        주) 투자금액은 지분 및 대출 투자금액 합계 또는 실제 투자금, 수익률은 IRR (Internal Rate of Return) 기준. 별도
        표기 없는 수치는 목표 및 예상 수치임
      </p>
    </section>
  );
}
