import React from 'react';

export default function Process() {
  return (
    <section id="process" className="max-w-7xl m-auto flex flex-col gap-32 py-16 px-4 bg-[#faf9f6] md:p-24">
      <div className="flex flex-col gap-4" data-aos="fade-right">
        <h2 className="text-3xl font-bold md:text-5xl">안전공투 진행 프로세스</h2>
        <p className="max-w-xl">
          최적의 투자 구조를 설정하여 투자자들의 안정성을 최우선으로 고려하고, 절세를 위한 법인 설립과 주주 구성, 정관
          세팅까지 제공합니다. 전문 변호사, 회계사, 세무사, 금융 전문가들이 작성한 공동투자 계약서를 전자 방식으로
          체결합니다. 투자금은 에스크로 신탁계좌로 안전하게 관리되며, 사업 진행 상황과 자금 집행, 수익금 배분 현황을
          스마트폰에서 투명하게 확인할 수 있습니다.
        </p>
      </div>
      <ul className="w-full grid grid-cols-1 md:grid-cols-3 gap-16">
        <li className="flex flex-col gap-4" data-aos="fade-in">
          <p className="text-5xl">📐</p>
          <h3 className="text-2xl font-bold">최적 투자 구조 세팅</h3>
          <p className="text-md">
            투자자들의 투자 안정성을 최우선으로, 절세까지 고려하여 법인설립, 주주구성, 정관 세팅까지
          </p>
        </li>
        <li className="flex flex-col gap-4" data-aos="fade-in">
          <p className="text-5xl">📑</p>
          <h3 className="text-2xl font-bold">전자계약으로 간편하게</h3>
          <p className="text-md">
            전문 변호사와 회계사, 세무사, 금융 투자 전문가들이 만든 공동투자 계약서를 전자로 체결 합니다.
          </p>
        </li>
        <li className="flex flex-col gap-4" data-aos="fade-in">
          <p className="text-5xl">📱</p>
          <h3 className="text-2xl font-bold">투명한 상황 공유</h3>
          <p className="text-md">
            투자금은 사업에만 쓰일 수 있게 에스크로 신탁계좌로 관리, 사업 진행상황, 자금집행, 수익금 배분 현황은
            언제든지 스마트폰에서 투명하게 확인할 수 있습니다.
          </p>
        </li>
      </ul>
    </section>
  );
}
