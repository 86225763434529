import React, { useState } from 'react';

const faqData = [
  {
    question: '공투 계약은 어떻게 이루어지나요?',
    answer:
      '계약은 전문 변호사, 회계사, 세무사, 금융 전문가들이 설계한 공동투자 표준계약서를 기반으로 전자 방식으로 체결됩니다. 안전하고 신속하게 계약을 완료할 수 있습니다. 투자 구조에 따라 지분투자, 대출투자 등으로 구분할 수 있고 여기에 소득공제, 상속 플랜과 같은 옵션을 선택할 수도 있습니다.',
  },
  {
    question: '공투할때 법인을 설립해야하나요?',
    answer:
      '다수의 투자자가 있는 경우 투자자 관리와 절세 측면에서 법인 설립이 유리합니다. 법인 설립, 주주 구성, 이사회 구성, 정관 세팅, 사업자 등록, 법인 관리 등의 모든 번거로운 절차를 한번에 안내해 드립니다.',
  },
  {
    question: '자금 조달도 가능한가요?',
    answer:
      '당사의 투자 기준에 부합하면 직접 투자를 검토하거나 타 금융기관 투자자와 연결도 가능합니다. 최적의 금융구조를 제안해 드리니 부담없이 상담을 받아보세요.',
  },
  {
    question: '공동투자에 따른 세금 문제는 어떻게 처리되나요?',
    answer:
      '세금 문제는 전문 세무사들이 투자 구조 설계 단계에서부터 최적화 전략을 마련하여 처리합니다. 법인 설립과 주주 구성 등 절세를 위한 모든 절차가 포함됩니다.',
  },
  {
    question: '수익 배분은 어떻게 이루어지나요?',
    answer:
      '수익금 관리는 에스크로 신탁계좌를 통해 투명하고 공정하게 이루어집니다. 모든 배분 과정은 스마트폰에서 실시간으로 확인할 수 있습니다.',
  },
  {
    question: '공동투자 진행상황은 투자자에게 어떻게 보고 되나요?',
    answer:
      '모든 프로젝트의 진행 상황, 자금 집행, 수익 배분 현황은 정기적으로 보고되며, 투자자들은 스마트폰을 통해 언제든지 이 정보를 실시간으로 확인할 수 있습니다.',
  },
];

export default function FAQ() {
  const [openIndex, setOpenIndex] = useState<number | null>(null);

  const toggleAnswer = (index: number) => {
    setOpenIndex(openIndex === index ? null : index);
  };

  return (
    <section
      id="faq"
      className="max-w-7xl m-auto flex flex-col gap-16 py-16 px-4 bg-[#faf9f6] md:p-24"
      data-aos="fade-in">
      <div className="flex flex-col gap-4">
        <h2 className="text-3xl font-bold md:text-5xl">자주 묻는 질문</h2>
        <p className="max-w-5xl">부동산 공동투자에 대해 궁금한 점이 있으신가요? 아래 FAQ를 참고해주세요.</p>
      </div>
      <dl className="w-full">
        {faqData.map((item, index) => (
          <div
            className={`flex flex-col py-4 px-2 w-full border border-x-0 border-t-gray-900 ${index === faqData.length - 1 ? 'border-b-gray-900' : 'border-b-0'}`}
            key={index}>
            <dt className="font-semibold cursor-pointer" onClick={() => toggleAnswer(index)}>
              <div className="flex items-center justify-between mt-2">
                Q. {item.question}
                <img
                  src="/icon/arrow-up.svg"
                  alt=""
                  className={`transition-transform duration-300 ${openIndex === index ? 'rotate-0' : 'rotate-180'}`}
                />
              </div>
            </dt>
            {/*{openIndex === index && (*/}
            <dd
              className={`transition-[max-height] duration-300 ease-in-out overflow-hidden mt-2 ${openIndex === index ? 'max-h-96' : 'max-h-0'}`}>
              A. {item.answer}
            </dd>
            {/*)}*/}
          </div>
        ))}
      </dl>
    </section>
  );
}
