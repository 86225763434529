import { useState } from 'react';
import Slider, { Settings } from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

const testimonials = [
  {
    text: '법률적인 부분까지 세심하게 관리해줘서 믿고 투자할 수 있었습니다. 안정적인 수익과 철저한 보안 덕분에 더욱 만족합니다.',
    author: '변호사, 김 OO',
    emoji: '🧑🏻‍🏫',
  },
  {
    text: '전문가의 분석과 안전공투 인덱스를 통해 투자의 안정성을 확신할 수 있었습니다. 정확한 수익 배분도 인상적이었어요.',
    author: '감정평가사, 이 OO',
    emoji: '🧑🏻',
  },
  {
    text: '건설 분야에 종사하면서도 쉽게 참여할 수 있는 점이 좋았습니다. 공투를 통해 새로운 투자 기회를 발견했어요.',
    author: '건축업, 박 OO',
    emoji: '👷🏻',
  },
  {
    text: '사업에 집중할 수 있도록 모든 관리 업무를 대행해줘서 정말 편리했습니다. 공투 덕분에 추가적인 수익 창출이 가능했습니다.',
    author: '스타트업 대표, 이 OO',
    emoji: '👩🏻‍💼',
  },
  {
    text: '소액으로도 큰 프로젝트에 참여할 수 있어서 좋았습니다. 정기적인 보고서를 통해 진행 상황을 투명하게 확인할 수 있었어요.',
    author: '주부, 김 OO',
    emoji: '👩🏻‍🦳',
  },
  {
    text: '다양한 부대 서비스를 통해 세금 최적화와 법인 설립까지 지원받아, 안정적인 투자 환경을 경험했습니다. 신뢰할 수 있는 파트너입니다.',
    author: '전 IT대기업 임원, 최 OO',
    emoji: '🧑🏻‍💼',
  },
  {
    text: '투자 리스크 관리와 투명한 정보 제공이 탁월했습니다. 공투를 통해 안정적인 수익을 꾸준히 올리고 있습니다.',
    author: '금융투자회사 임원, 장 OO',
    emoji: '👨🏻‍💼',
  },
  {
    text: '복잡한 법률 문제까지 해결해주는 안전공투 컨설팅 서비스 덕분에 안심하고 투자할 수 있었습니다. 철저한 고객 정보 보호도 믿음이 갔어요.',
    author: '변호사, 정 OO',
    emoji: '👩🏻‍🏫',
  },
  {
    text: '공투 프로젝트의 철저한 분석과 신뢰도 높은 평가 점수 덕분에 확신을 가지고 투자했습니다. 결과적으로 높은 수익을 얻을 수 있었습니다.',
    author: '감정평가사, 김 OO',
    emoji: '🧑🏻',
  },
  {
    text: '사업을 운영하면서 추가적인 투자 수익을 얻을 수 있는 좋은 기회였습니다. 공투 덕분에 사업 확장에 큰 도움이 되었습니다.',
    author: '스타트업 대표, 박 OO',
    emoji: '👩🏻‍💼',
  },
  {
    text: '계약, 세금, 수익 배분 모두 전문가가 처리해주니 불필요한 업무를 최소화할 수 있어 본업에만 집중할 수 있었습니다. 고객과의 신뢰가 더욱 두터워졌습니다.',
    author: '공투 리더, 이 OO',
    emoji: '👨🏻‍💼',
  },
  {
    text: '프로젝트 운영에 필요한 복잡한 절차를 전문가들이 알아서 해주니 신경 쓸 일이 줄어들었습니다. 고객과의 투명한 소통 덕분에 신뢰를 쌓을 수 있었습니다.',
    author: '디벨로퍼, 박 OO',
    emoji: '👩🏻‍💼',
  },
];

export default function Testimonials() {
  const [currentIndex, setCurrentIndex] = useState(0);

  const settings: Settings = {
    centerMode: true,
    centerPadding: '0',
    slidesToShow: 3,
    focusOnSelect: true,
    autoplay: true,
    speed: 1000,
    autoplaySpeed: 5000,
    beforeChange: (current: any, next: any) => setCurrentIndex(next),
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };

  return (
    <section id="testimonials" className="py-16 bg-white">
      <h2 className="text-3xl font-bold text-center mb-24 md:text-5xl" data-aos="fade-in">
        고객 후기
      </h2>
      <Slider {...settings} className="mx-auto md:max-w-full" data-aos="zoom-out">
        {testimonials.map((testimonial, index) => (
          <div
            key={index}
            className={`!w-11/12 py-4 px-8 m-2 bg-white transition-transform duration-300 border-solid border border-gray-300 rounded-lg min-h-32 max-h-48 h-60 md:!w-10/12 ${
              index === currentIndex ? 'transform scale-105' : 'transform scale-95'
            }`}>
            <blockquote className="flex flex-col justify-between gap-4 h-full">
              <p className={`lg:text-lg ${index === currentIndex && 'font-bold'}`}>{testimonial.text}</p>
              <footer className={`flex items-center justify-end gap-2 ${index === currentIndex && 'font-bold'}`}>
                - {testimonial.author} <span className="text-5xl">{testimonial.emoji}</span>
              </footer>
            </blockquote>
          </div>
        ))}
      </Slider>
    </section>
  );
}
