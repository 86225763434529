import React from 'react';

export default function IntroductionNeeds() {
  return (
    <section id="introduction" className="py-16 px-4 bg-[#faf9f6] md:p-24">
      <div className="max-w-[1024px] m-auto h-full flex justify-center items-center">
        <div className="flex flex-col gap-24 md:gap-36">
          <div className="flex gap-4 items-center" data-aos="zoom-in-up">
            <p className="text-5xl">👨🏻‍💼</p>
            <div className="flex flex-col gap-2">
              <p className="text-xl font-bold md:text-2xl">
                &quot;지인들과 공투를 하기로 했는데 어디서 부터 시작해야할지 막막해요.&quot;
              </p>
              <p className="text-xl">(금융지주사 임직원)</p>
            </div>
          </div>
          <div className="flex gap-4 items-center justify-end" data-aos="zoom-in-up">
            <div className="flex flex-col gap-2">
              <p className="text-xl text-right font-bold md:text-2xl">
                &quot;가족, 친척과 공투를 하려고 하는데 계약서를 어떻게 써야할지 궁금해요.&quot;
              </p>
              <p className="text-xl text-right">(전문직 A씨)</p>
            </div>
            <p className="text-5xl">👩🏻‍⚕️</p>
          </div>
          <div className="flex gap-4 items-center justify-end" data-aos="zoom-in-up">
            <p className="text-5xl">🙎🏻‍♀️</p>
            <div className="flex flex-col gap-2">
              <p className="text-xl font-bold md:text-2xl">
                &quot;신규법인을 만들어서 직원들과 공투를 하고 싶은데 법인설립, 주주구성, 이사회 구성은 어떻게 하는게
                좋을까요?&quot;
              </p>
              <p className="text-xl">(로컬 디벨로퍼)</p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
