import React from 'react';

const teams = [
  {
    img: '/img/team-1.jpg',
    name: '이지수',
    position: '디지털 부동산 투자 마스터',
    description: '부동산 시장에 대한 깊은 이해와 전문적인 분석 능력을 갖고 있습니다.',
    url: '',
  },
  {
    img: '/img/team-2.jpg',
    name: '이상화',
    position: '공인회계사 | 금융과 회계의 마스터',
    description: '다양한 부동산 프로젝트에 대한 폭넓은 경험과 전문적인 분석 능력을 갖고 있습니다.',
    url: '',
  },
  {
    img: '/img/team-3.jpg',
    name: '조아영',
    position: '투자자산운용사 | 공투마스터',
    description: '아파트 투자부터 대형 시행사업까지 커버하는 올라운드 플레이어 입니다.',
    url: '',
  },
];

export default function Team() {
  return (
    <section id="process" className="bg-white">
      <div className="flex flex-col gap-24 max-w-7xl m-auto py-16 px-4 md:p-24">
        <div className="flex flex-col gap-4" data-aos="fade-right">
          <div className="flex flex-col gap-4">
            <h3 className="text-xl font-bold md:text-3xl">전문가들과 함께</h3>
            <h2 className="text-3xl font-bold md:text-5xl">안전공투 전문가 그룹</h2>
          </div>
          <p className="max-w-xl">부동산 투자 전문가들로 구성된 우리 팀입니다.</p>
        </div>
        <div className="grid grid-cols-1 md:grid-cols-3 gap-16" data-aos="fade-up">
          {teams.map((team, index) => (
            <div
              key={index}
              className="flex flex-col items-center bg-[#faf9f6] p-6 rounded-lg shadow-xl cursor-pointer transform transition-transform duration-300 hover:-translate-y-3">
              <img src={team.img} alt={team.name} className="w-32 h-32 rounded-full mb-4" />
              <h3 className="text-xl font-bold mb-2">{team.name}</h3>
              <p className="text-gray-600 mb-4">{team.position}</p>
              <p className="text-center">{team.description}</p>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
}
