import React, { useEffect } from 'react';
import Router from '@/routes/router';
import Header from '@/components/Header';
import Footer from '@/components/Footer';
import AOS from 'aos';
import 'aos/dist/aos.css';

export default function Home() {
  useEffect(() => {
    AOS.init({ once: true, duration: 1000, easing: 'ease-in-out' });
  }, []);

  return (
    <main>
      <Header />
      <Router />
      <Footer />
    </main>
  );
}
