import { Routes, Route } from 'react-router-dom';
import Read from '@/pages/read';
import Investor from '@/pages/investor';

const Router = () => {
  return (
    <Routes>
      <Route path="/" element={<Read />} />
      <Route path="/investor" element={<Investor />} />
    </Routes>
  );
};

export default Router;
