import React, { useEffect, useState } from 'react';

export default function SCIIndex() {
  const [visibleMessages, setVisibleMessages] = useState([false, false, false, false]);

  useEffect(() => {
    const handleScroll = () => {
      const sciElement = document.querySelector('#sci');
      if (sciElement) {
        const rect = sciElement.getBoundingClientRect();
        const isInViewport = rect.top >= 0 && rect.bottom <= window.innerHeight;
        if (isInViewport) {
          const timers = visibleMessages.map(
            (_, index) =>
              setTimeout(() => {
                setVisibleMessages((prev) => {
                  const newVisibleMessages = [...prev];
                  newVisibleMessages[index] = true;
                  return newVisibleMessages;
                });
              }, index * 700), // Adjust the delay as needed
          );

          return () => {
            timers.forEach(clearTimeout);
          };
        }
      }
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <section
      id="sci"
      className="max-w-7xl m-auto flex flex-col justify-center md:gap-12 gap-32 py-16 px-4 bg-[#faf9f6] md:p-24 md:flex-row">
      <div className="min-w-min md:min-w-[450px] max-w-5xl py-6 px-4 bg-[#A0C0D7] shadow-lg rounded-lg">
        <div className="space-y-4">
          <div
            className={`flex items-start justify-end ${visibleMessages[0] ? 'opacity-100 transition-opacity duration-1000' : 'opacity-0'}`}>
            <div className="bg-[#FFE942] p-4 rounded-lg shadow-md max-w-xs relative">
              <p className="text-sm">공투 해보실래요?</p>
              <div className="absolute top-4 left-full transform -translate-y-1/2">
                <div className="w-0 h-0 border-t-8 border-t-transparent border-b-8 border-b-transparent border-l-8 border-l-[#FFE942]"></div>
              </div>
            </div>
          </div>
          <div
            className={`flex items-start space-x-2 ${visibleMessages[1] ? 'opacity-100 transition-opacity duration-1000' : 'opacity-0'}`}>
            <img src="/img/profile.png" alt="Profile B" className="w-8 h-8 rounded-full" />
            <div>
              <strong className="block text-xs text-[#404D56]">김OO</strong>
              <div className="bg-white p-4 rounded-lg shadow-md max-w-xs relative">
                <p className="text-sm">강사님과 한다면 믿고 하고 싶어요!</p>
                <div className="absolute top-4 right-full transform -translate-y-1/2">
                  <div className="w-0 h-0 border-t-8 border-t-transparent border-b-8 border-b-transparent border-r-8 border-r-white"></div>
                </div>
              </div>
            </div>
          </div>
          <div
            className={`flex items-start space-x-2 ${visibleMessages[2] ? 'opacity-100 transition-opacity duration-1000' : 'opacity-0'}`}>
            <img src="/img/profile.png" alt="Profile C" className="w-8 h-8 rounded-full" />
            <div>
              <strong className="block text-xs text-[#404D56]">이OO</strong>
              <div className="bg-white p-4 rounded-lg shadow-md max-w-xs relative">
                <p className="text-sm">그럼 우리 SCI Index 평가 받아요.</p>
                <div className="absolute top-4 right-full transform -translate-y-1/2">
                  <div className="w-0 h-0 border-t-8 border-t-transparent border-b-8 border-b-transparent border-r-8 border-r-white"></div>
                </div>
              </div>
            </div>
          </div>
          <div
            className={`flex items-start justify-end ${visibleMessages[3] ? 'opacity-100 transition-opacity duration-1000' : 'opacity-0'}`}>
            <div className="bg-[#FFE942] p-4 rounded-lg shadow-md max-w-xs relative">
              <p className="text-sm">네, 그럼 진행할게요!</p>
              <div className="absolute top-4 left-full transform -translate-y-1/2">
                <div className="w-0 h-0 border-t-8 border-t-transparent border-b-8 border-b-transparent border-l-8 border-l-[#FFE942]"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="max-w-lg p-8 bg-white shadow-lg rounded-lg">
        <h1 className="text-2xl font-bold mb-12">당신의 공투는 얼마나 안정적인가요?</h1>
        <p>
          <strong>SCI Index란?</strong>
          <br />
          &quot;안전공투 인덱스&quot;라는 지표를 통해 투자 안정성을 확인할 수 있습니다. 이 지표는 약 3000억 원 규모의
          공동투자를 진행하며 쌓은 노하우를 바탕으로 개발된 것으로, 내가 참여하려는 공투가 어느 정도의 위험성을 가지고
          있는지 객관적으로 판단할 수 있게 해줍니다.
          <br />
          <br />
          &quot;안전공투 인덱스&quot;를 통해 투자 리스크를 사전에 파악하고, 안정적인 투자 결정을 내릴 수 있습니다. 이는
          투자자에게 높은 신뢰도를 제공하며, 안전한 투자를 지원합니다.
        </p>
      </div>
    </section>
  );
}
