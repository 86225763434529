import React, { useState } from 'react';

const LoadingSpinner = () => (
  <div className="fixed inset-0 flex items-center justify-center bg-gray-100 bg-opacity-50">
    <div className="loader ease-linear rounded-full border-4 border-t-4 border-gray-200 h-12 w-12"></div>
  </div>
);

export default function EmailSubscription() {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [phone, setPhone] = useState('');

  // const handleChange = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>): void => {
  //   const { name, value } = e.target;
  //   setFormData((prev) => ({ ...prev, [name]: value }));
  // };

  const setCurrentTime = () => {
    const TIME_ZONE = 1000 * 60 * 60 * 9;
    const resultTime = new Date(new Date().getTime() + TIME_ZONE).toISOString().replace('T', ' ').slice(0, -5);
    return String(resultTime);
  };

  const form = document.getElementById('submit-to-google-sheet-phone') as HTMLFormElement;

  const handleContact = (event: any) => {
    event.preventDefault();
    setIsSubmitting(true);

    const scriptURL =
      'https://script.google.com/macros/s/AKfycbxhlvkMcceQb2oJ_i2hgw8NDZuXxrBquIEq3AppqY9GOtzuO3D5mcWDo9TPVq2q0bXa/exec';

    fetch(scriptURL, { method: 'POST', body: new FormData(form) })
      .then((response) => {
        console.log('Success!', response);
        handleSubmitPhone();
      })
      .catch((error) => {
        setIsSubmitting(false);
        alert('예기치 못한 오류가 발생했습니다.' + error.message);
        console.error('Error!', error.message);
      });
  };

  const handleSubmitPhone = () => {
    fetch('https://api.wefunding.com/api/v1/gongtoo/subscriber', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        phoneNumber: phone,
        type: 'INTRODUCTION_ONLY',
      }),
    })
      .then((response) => {
        console.log('Success!', response);
        setPhone('');
        form.reset();
        alert('신청이 완료되었습니다.');
        setIsSubmitting(false);
      })
      .catch((error) => {
        setIsSubmitting(false);
        alert('예기치 못한 오류가 발생했습니다.' + error.message);
        console.error('Error!', error.message);
      });
  };

  return (
    <section id="email-subscription" className="bg-white" data-aos="fade-in">
      {isSubmitting && <LoadingSpinner />}
      <div className="max-w-7xl m-auto w-full flex flex-col gap-10 py-16 px-4 md:p-24">
        <div className="flex flex-col gap-4">
          <h2 className="text-3xl font-bold md:text-5xl">안전 공투 상품 소개 받기</h2>
          <p className="max-w-5xl">안전 공투 상품 소개를 받고 싶으시다면 아래에 전화번호를 입력해주세요.</p>
        </div>
        <form id="submit-to-google-sheet-phone" className="max-w-4xl flex flex-col flex-start" onSubmit={handleContact}>
          <div className="w-full h-16 md:w-1/2 relative">
            <input className="hidden" name="접수시간" value={setCurrentTime()} />
            <input
              id="phone"
              type="phone"
              name="전화번호"
              placeholder="전화번호 입력"
              onChange={(e) => setPhone(e.target.value)}
              className="px-4 border border-gray-300 rounded-full mb-4 w-full h-16"
              required
            />
            <button
              type="submit"
              className="bg-gray-800 text-white w-40 h-16 px-4 rounded-full text-left absolute right-0 cursor-pointer">
              신청하기
            </button>
          </div>
        </form>
      </div>
    </section>
  );
}
