import React, { useState } from 'react';
import Slider, { Settings } from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

const features = [
  {
    title: '공투 리더는 사업에만 집중하세요',
    description:
      '복잡한 세무, 회계, 법률 문제는 전문가가 처리하므로, 리더는 핵심 비즈니스에만 집중할 수 있습니다. 모든 관리 업무를 맡겨 더 큰 성과를 이끌어내세요.',
  },
  {
    title: '전문가의 지속적 지원 및 상담',
    description:
      '투자 전 과정에서 전문가의 지속적인 지원과 상담을 받을 수 있습니다. 법인설립, 주주/이사회 구성, 정관, 계약서 작성, 자금조달, 투자자들과 소통 등 모든 부분을 지원 받으세요.',
  },
  {
    title: '공동 투자자들과 신뢰 UP!!',
    description:
      '투명한 소통과 정기적인 보고서를 통해 고객의 신뢰를 높일 수 있습니다. 신뢰를 바탕으로 고객과의 장기적인 관계를 구축하세요.',
  },
  {
    title: '고객 정보는 유출되지 않습니다',
    description:
      '고객 정보는 철저히 보호되며, 최고 수준의 보안 시스템이 적용됩니다. 개인 정보의 유출 없이 안심하고 투자하세요.',
  },
  {
    title: '추가 서비스 (자금조달)',
    description:
      '필요한 자금을 유연하게 조달할 수 있는 다양한 옵션을 제공합니다. 투자에 필요한 자금을 신속하고 효율적으로 확보하세요.',
  },
  {
    title: '다양한 부대 서비스 (가족법인, 소득공제, 상속증여자문)',
    description:
      '가족법인 설립, 소득공제 혜택, 상속 및 증여 자문 등 다양한 부대 서비스를 제공합니다. 고객의 재산 보호와 증대를 위해 맞춤형 솔루션을 제안합니다.',
  },
  {
    title: '소액으로 고액 투자',
    description:
      '소액으로도 고액 부동산 프로젝트에 참여할 수 있는 기회를 제공합니다. 다양한 프로젝트에 분산 투자하여 리스크를 낮추고 수익을 극대화하세요.',
  },
  {
    title: '세금 최적화',
    description:
      '최적의 세금 전략을 제안하여 투자 수익을 최대화합니다. 세금 부담을 최소화하고, 법적 절차를 준수하며 투자하세요.',
  },
];

export default function FeaturesThird() {
  const [currentIndex, setCurrentIndex] = useState(0);

  const settings: Settings = {
    centerMode: true,
    centerPadding: '0',
    slidesToShow: 3,
    focusOnSelect: true,
    autoplay: true,
    speed: 1000,
    autoplaySpeed: 5000,
    beforeChange: (current: any, next: any) => setCurrentIndex(next),
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };

  return (
    // <section id="featuresThird" className="flex flex-col gap-16 text-center py-16 px-4 bg-white md:px-16">
    <section id="featuresThird" className="text-center py-16 px-4 bg-white">
      <div className="flex flex-col gap-4" data-aos="zoom-in-up">
        <h3 className="text-xl font-bold md:text-3xl">왜 안전공투인가?</h3>
        <h1 className="text-3xl font-bold md:text-5xl">공투를 더 강력하게</h1>
      </div>
      <p className="max-w-4xl mx-auto my-8 text-center" data-aos="fade-up">
        안전공투는 공투를 이끄는 리더는 사업에만 집중할 수 있게 합니다. 복잡한 계약과 투자자 리포팅은 저희에게 맡기세요.
        전문가의 지원을 통해 투명한 관리와 정기적 보고, 세금 최적화, 가족법인 설립 등 다양한 부대 서비스를 제공할 수
        있습니다.
      </p>
      <Slider {...settings} className="mx-auto md:max-w-full" data-aos="zoom-out">
        {features.map((feature, index) => (
          <div
            key={index}
            className={`${index === currentIndex && '!bg-gray-600 shadow-inner'} !w-11/12 py-4 px-8 m-2 bg-white shadow-md transition-transform duration-300 border-solid border border-gray-300 rounded-lg min-h-36 max-h-96 h-60 md:!w-10/12 ${
              index === currentIndex ? 'transform scale-105' : 'transform scale-95'
            }`}>
            <blockquote className="flex flex-col justify-center gap-8 text-center h-full">
              <p className={`lg:text-xl ${index === currentIndex && 'font-bold text-white'}`}>{feature.title}</p>
              {/*<footer>{feature.description}</footer>*/}
              <footer className={`${index === currentIndex && 'text-white'}`}>{feature.description}</footer>
            </blockquote>
          </div>
        ))}
      </Slider>
    </section>
  );
}
