import React, { ChangeEvent, useState } from 'react';

const LoadingSpinner = () => (
  <div className="fixed inset-0 flex items-center justify-center bg-gray-100 bg-opacity-50">
    <div className="loader ease-linear rounded-full border-4 border-t-4 border-gray-200 h-12 w-12"></div>
  </div>
);

export default function ContractForm() {
  const [name, setName] = useState('');
  const [phone, setPhone] = useState('');
  const [email, setEmail] = useState('');
  const [isLeader, setIsLeader] = useState(false);

  const [isSubmitting, setIsSubmitting] = useState(false);

  const resetState = () => {
    setName('');
    setPhone('');
    setEmail('');
    setIsSubmitting(false);
  };

  const form = document.getElementById('submit-to-google-sheet-phone') as HTMLFormElement;

  const handleSubmitSheet = (event: any) => {
    event.preventDefault();

    console.log('form', form);
    const scriptURL =
      'https://script.google.com/macros/s/AKfycbyuFF95WExaWwhJFAFNMXYYzI70qmzXfbTS6cz5qEu5eTtD6OId0b2C2R6PrnyqJl1Q/exec';

    if (name && phone && email) {
      const formData = new FormData();
      formData.append('이름', name);
      formData.append('전화번호', phone);
      formData.append('이메일', email);
      formData.append('접수시간', setCurrentTime());
      formData.append('리더여부', isLeader ? '네' : '아니오');

      setIsSubmitting(true);
      fetch(scriptURL, { method: 'POST', body: formData })
        .then((response) => {
          console.log('Success!', response);
          handleSubmitEmail();
        })
        .catch((error) => {
          setIsSubmitting(false);
          alert('예기치 못한 오류가 발생했습니다.' + error.message);
          console.error('Error!', error.message);
        });
    } else {
      alert('이름, 전화번호, 이메일 입력값을 확인해주세요.');
    }
  };

  const handleSubmitEmail = () => {
    fetch('https://api.wefunding.com/api/v1/gongtoo/subscriber', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        name: name,
        phoneNumber: phone,
        email,
        isLeader,
        type: 'CONTRACT_SUBSCRIPTION',
      }),
    })
      .then((response) => {
        console.log('Success!', response);
        resetState();
        alert('신청이 완료되었습니다.');
      })
      .catch((error) => {
        setIsSubmitting(false);
        alert('예기치 못한 오류가 발생했습니다.' + error.message);
        console.error('Error!', error.message);
      });
  };

  const setCurrentTime = () => {
    const TIME_ZONE = 1000 * 60 * 60 * 9;
    const resultTime = new Date(new Date().getTime() + TIME_ZONE).toISOString().replace('T', ' ').slice(0, -5);
    return String(resultTime);
  };

  return (
    <section id="process" className="bg-white">
      {isSubmitting && <LoadingSpinner />}
      <div className="flex flex-col gap-8 max-w-7xl m-auto py-16 px-4 md:p-24 md:gap-24">
        <h1 className="text-3xl font-bold md:text-5xl">안전공투 표준계약서를 무료로 받아보세요!</h1>
        <div className="flex flex-col gap-14 justify-between md:flex-row">
          <div className="max-w-lg p-8 bg-white shadow-lg rounded-lg">
            <form id="submit-to-google-sheet-contract" onSubmit={handleSubmitSheet}>
              <div className="mb-4">
                <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="name">
                  이름
                </label>
                <input
                  id="name"
                  type="text"
                  name="이름"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                  className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                />
              </div>
              <div className="mb-4">
                <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="phoneNumber">
                  전화번호
                </label>
                <input
                  id="phoneNumber"
                  type="text"
                  name="전화번호"
                  value={phone}
                  onChange={(e) => setPhone(e.target.value)}
                  className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                />
              </div>
              <div className="mb-4">
                <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="email">
                  이메일
                </label>
                <input
                  id="email"
                  type="email"
                  name="이메일"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                />
              </div>
              <div className="mb-4">
                <label className="block text-gray-700 text-sm font-bold mb-2">공동투자를 이끄는 리더인가요?</label>
                <div className="flex items-center mb-2">
                  <input
                    id="leader-yes"
                    type="radio"
                    name="리더여부"
                    value="yes"
                    onChange={(e) => setIsLeader(e.target.value === 'yes')}
                    className="mr-2 leading-tight"
                  />
                  <label htmlFor="leader-yes" className="text-gray-700">
                    네
                  </label>
                </div>
                <div className="flex items-center">
                  <input
                    id="leader-no"
                    type="radio"
                    name="리더여부"
                    value="no"
                    onChange={(e) => setIsLeader(e.target.value === 'yes')}
                    className="mr-2 leading-tight"
                  />
                  <label htmlFor="leader-no" className="text-gray-700">
                    아니오, 저는 투자를 고려중인 사람입니다.
                  </label>
                </div>
              </div>
              <div className="relative group">
                <button
                  type="submit"
                  className="w-full bg-gray-500 hover:bg-gray-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline">
                  공투 표준 계약서 받기
                </button>
                <div className="absolute bottom-full right-0 mb-2 hidden group-hover:block w-64 p-2 bg-gray-700 text-white text-xs rounded">
                  위 버튼을 클릭하시면 마케팅 정보 수신 동의 및 개인정보 수집, 활용에 동의하시는 것으로 간주됩니다.
                </div>
              </div>
            </form>
          </div>
          <div className="flex flex-col gap-8 items-center w-full md:w-1/2 md:items-start">
            <img className="w-2/3 md:w-1/2" src="/img/contract.png" alt="" />
            <div className="flex flex-col gap-4 md:flex-row">
              <p className="text-sm md:w-2/3">
                이 계약서는 변호사, 회계사, 세무사, 금융 투자 전문가들이 협력하여 설계한 것이며, 법적 안전성을
                보장합니다. 전자 계약 방식으로 편리하고 신속하게 체결할 수 있어, 불필요한 절차를 줄이고 투자자들에게
                안전하고 신뢰할 수 있는 법적 보호를 제공합니다.
              </p>
              <p className="text-sm">
                {
                  '아래와 같은 장점이 있습니다. \n1) 신속한 의사결정이 가능 \n2) 책임소재 명확화 \n3) 투자금 배분의 명확화 \n※ 본 계약서는 기본형이고 고급형은 프로젝트의 특성에 맞는 투자구조, 세금설계 등이 반영됩니다.'
                }
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
