import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

export default function Header() {
  const [scrolled, setScrolled] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 50) {
        setScrolled(true);
      } else {
        setScrolled(false);
      }
    };

    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);

  return (
    <header
      className={`fixed !z-[999] top-0 left-0 w-full p-4 transition-colors duration-300 ${scrolled ? 'bg-gray-800' : 'bg-transparent'}`}>
      <div className="mx-auto flex justify-between items-center md:max-w-7xl">
        <div className="transition-colors duration-300 text-white cursor-pointer text-2xl font-bold">안전공투</div>
        <nav className="flex gap-8 text-black">
          <p className="transition-colors duration-300 text-white cursor-pointer text-lg" onClick={() => navigate('/')}>
            공투 리드
          </p>
          <p
            className="transition-colors duration-300 text-white cursor-pointer text-lg"
            onClick={() => navigate('/investor')}>
            공투 투자자
          </p>
        </nav>
      </div>
    </header>
  );
}
