import React from 'react';

export default function Footer() {
  return (
    <footer className="flex flex-col gap-16 py-8 px-4 bg-gray-800 text-white md:py-12 md:px-24">
      <div className="max-w-7xl m-auto w-full flex flex-col gap-10 md:flex-row">
        <div className="flex flex-col gap-4">
          <p className="text-xl font-bold mb-4">Contact</p>
          <p className="flex text-sm rounded-3xl bg-gray-50 w-[40px] p-2">
            <a href="https://open.kakao.com/o/s6fOG6Mg" target="_blank" rel="noopener noreferrer">
              <img src="/icon/kakao.png" alt="" />
            </a>
          </p>
          <p className="flex text-sm">
            문의: <a href="mailto:support@theworkskorea.com">&nbsp;support@theworkskorea.com</a>
          </p>
          <p className="flex gap-4 text-sm">주소: 서울 강남구 테헤란로 410, 15층</p>
        </div>

        <div className="flex flex-col gap-4">
          <p className="text-xl font-bold mb-4">Investment Disclosure</p>
          <p className="text-sm">
            {
              '당사는 금융관련법규를 준수하며 투자수익의 원금을 보장하지 않습니다. \n모든 투자에 대한 책임은 투자자에게 있습니다.'
            }
          </p>
        </div>
      </div>
      <p className="text-center text-xs">&copy; 2024 주식회사 더웍스코리아. All rights reserved.</p>
    </footer>
  );
}
