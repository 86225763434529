import Introduction from './components/Introduction';
import Features from './components/Features';
import FeaturesSecond from './components/FeaturesSecond';
import FeaturesThird from './components/FeaturesThird';
import Performance from './components/Performance';
import Testimonials from './components/Testimonials';
import Process from './components/Process';
import Team from './components/Team';
import FAQ from './components/FAQ';
import EmailSubscription from './components/EmailSubscription';
import IntroductionNeeds from './components/IntroductionNeeds';
import ContractForm from './components/ContractForm';
import SCIIndex from './components/SCIIndex';

export default function Investor() {
  return (
    <>
      <Introduction />
      <IntroductionNeeds />
      <Features />
      <FeaturesSecond />
      <FeaturesThird />
      <Performance />
      <Testimonials />
      <SCIIndex />
      <Process />
      <Team />
      <ContractForm />
      <FAQ />
      <EmailSubscription />
    </>
  );
}
