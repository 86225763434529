import React from 'react';

export default function Introduction() {
  return (
    <section
      id="introduction"
      // className="py-16 px-16 bg-[#faf9f6]"
      className="py-16 px-4 bg-fixed bg-cover bg-no-repeat bg-center-bottom bg-blend-multiply h-[750px] md:p-24"
      style={{
        backgroundImage: 'url(/img/banner_img.jpg), linear-gradient(to top, rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.7))',
      }}>
      <div className="max-w-7xl m-auto h-full flex justify-between items-center">
        <div className="flex flex-col gap-10">
          <h1 className="text-4xl text-white font-bold md:text-5xl !leading-tight">
            <p className="text-lg mb-2">안전공투 지원사업</p>
            {'당신의 공투를\n' + '지원 합니다'}
          </h1>
          <p className="text-lg text-white">{'공투를 기획중이신가요? \n 사업에만 집중할수 있는 지원을 받아보세요!'}</p>
        </div>
      </div>
    </section>
  );
}
