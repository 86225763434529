export default function FeaturesSecond() {
  return (
    <section
      id="featuresSecond"
      className="text-center flex justify-center md:h-[650px]"
      style={{
        backgroundImage: 'linear-gradient(to top, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.7)), url(/img/img.jpg)',
        backgroundPositionY: 'center',
        backgroundSize: 'cover',
      }}>
      <div className="max-w-7xl m-auto flex flex-col gap-24 py-16 px-4 text-white">
        <div className="flex flex-col gap-4" data-aos="zoom-in-up">
          <h3 className="text-xl font-bold md:text-3xl">누적 실적 3,000억원 이상!</h3>
          <h1 className="text-3xl font-bold md:text-5xl">안전 공투의 모든 노하우를 드립니다</h1>
        </div>

        <div className="flex flex-col gap-24 justify-center h-auto md:!gap-12">
          <div className="flex flex-col gap-4" data-aos="fade-up">
            <h3 className="text-xl font-bold md:text-3xl">공투를 진행하는 당신을 응원합니다!</h3>
            <p className="text-sm">
              고수익을 창출할 수 있는 부동산 투자의 수익을 나누는 당신은 사회적, 경제적으로 좋은 영향을 주고 있습니다.
              그런 당신을 저희가 지원해 드립니다.
            </p>
          </div>
          <div className="flex gap-4 justify-evenly">
            <div className="flex flex-col items-center gap-2" data-aos="fade-up">
              <h1 className="text-2xl font-bold md:text-5xl">3,000억+</h1>
              <p className="text-sm">{'누적 투자 금액\n(대출 포함)'}</p>
            </div>

            <div className="flex flex-col gap-2" data-aos="fade-up">
              <h1 className="text-2xl font-bold md:text-5xl">1,000개+</h1>
              <p className="text-sm">프로젝트 수</p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
