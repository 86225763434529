import React from 'react';

export default function Features() {
  return (
    <section id="features" className="flex flex-col gap-32 py-16 px-4 bg-white md:p-24">
      <div className="flex flex-col gap-4 text-center" data-aos="zoom-in-up">
        <h3 className="text-xl font-bold md:text-3xl">공투를 지원해 드립니다!</h3>
        <h1 className="text-3xl font-bold text-center md:text-5xl">더 쉽고 안전한 공동투자</h1>
      </div>
      <ul className="max-w-7xl mx-auto grid grid-cols-1 md:grid-cols-3 gap-16">
        <li className="flex flex-col gap-4" data-aos="fade-in">
          <div className="text-5xl">🖥️</div>
          <h3 className="text-2xl font-bold">디지털로 간편하게!</h3>
          <p className="text-md">
            다수의 투자자들도 걱정 없습니다. SPC 법인 설립, 투자 계약, 투자금 관리, 현장 관리, 투자자 리포팅 이 모든
            것을 디지털로 간편하게 관리해 드립니다.
          </p>
        </li>
        <li className="flex flex-col gap-4" data-aos="fade-in">
          <div className="text-5xl">💯</div>
          <h3 className="text-2xl font-bold">내 공투 상품의 점수는요?</h3>
          <p className="text-md">
            3000억 원 이상의 공동투자 경험을 바탕으로 구축된 데이터와 분석을 통해 산출되는 공투 상품 점수로 투자자분들께
            상품의 안전성을 어필해보세요.
          </p>
        </li>
        <li className="flex flex-col gap-4" data-aos="fade-in">
          <div className="text-5xl">🔐</div>
          <h3 className="text-2xl font-bold">투자자 정보보안은 철저하게!</h3>
          <p className="text-md">
            공투 투자자들의 정보는 안전하게 관리되어 외부에 유출되지 않습니다! 소중한 투자자분들의 정보를 철저하게
            관리하세요!
          </p>
        </li>
      </ul>
    </section>
  );
}
